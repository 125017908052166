import videoStyle from './VideoPopup.module.css';

import cancelIcon from '../assets/cancel-icon.svg';

function VideoPopup({ isOpen, src, onClose }) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className={videoStyle.mainContainer} onClick={onClose}>
            <div className={videoStyle.content} onClick={e => e.stopPropagation()}>
                <div className={videoStyle.header}>
                    <button className={videoStyle.closeButton} onClick={onClose}>
                        <img style={{filter: 'invert(100%)'}} src={cancelIcon} alt={'icon'}/>
                    </button>
                </div>
                <div className={videoStyle.iframeContainer}>
                <iframe
                        src={src}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        title="Video"
                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

export default VideoPopup;