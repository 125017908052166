import homeStyle from "./Home.module.css";

import alettiLogo from "../assets/logos/aletti-logo.svg";
import baLogo from "../assets/logos/ba-logo.svg";

import allianzLogo from "../assets/logos/allianz-logo.svg";
import amundiLogo from "../assets/logos/amundi-logo.svg";
import blackrockLogo from "../assets/logos/blackrock-logo.svg";
import capitalLogo from "../assets/logos/capitalgroup-logo.svg"
import fidelityLogo from "../assets/logos/fidelity-logo.svg";
import invescoLogo from "../assets/logos/invesco-logo.svg";
import jpmorganLogo from "../assets/logos/jpmorgan-logo.svg";
import pictetLogo from "../assets/logos/pictet-logo.svg";
import pimcoLogo from "../assets/logos/pimco-logo.svg";
import raiffeseiLogo from "../assets/logos/raiffesein-logo.svg";
import robecoLogo from "../assets/logos/robeco-logo.svg";
import ubsLogo from "../assets/logos/ubs-logo.svg";

import mainImg from "../assets/header-img.png"
import assetLine from "../assets/asset-line.svg"
import VideoPopup from "../components/VideoPopup";
import {useState} from "react";

function Home() {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [iframeSrc, setIframeSrc] = useState('');

    const openPopup = (videoUrl) => {
        setIframeSrc(videoUrl);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div className={homeStyle.mainContainer}>
            <VideoPopup isOpen={isPopupOpen} src={iframeSrc} onClose={closePopup}></VideoPopup>
            <div className={homeStyle.header}>
                <img src={alettiLogo} alt={'logo'} className={homeStyle.headerLogoAletti}/>
                <img src={baLogo} alt={'logo'} className={homeStyle.headerLogoBa}/>
            </div>
            <div className={homeStyle.mainImageContainer}>
                <img src={mainImg} alt={'logo'} style={{width: '100%'}}/>
            </div>
            <div className={homeStyle.bodyContainer}>
                <hr style={{width: '95vw'}}/>
                <h3 style={{fontWeight: 'normal'}}>SCOPRI GLI APPROFONDIMENTI</h3>
                <div className={homeStyle.gridContainer}>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929803754?h=6ce31fd22a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={allianzLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929798363?h=8c54c259b7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={amundiLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929803945?h=2da1e9baf6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={blackrockLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929803024?h=f05e519e27&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={capitalLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929802530?h=c7f4a370c7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={fidelityLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929803261?h=d4904fde1f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={invescoLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929802145?h=e41aff5d2b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={jpmorganLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929803516?h=dbc70b9ee3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={pictetLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929801968?h=9dc878312e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={pimcoLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929796776?h=c236ee55f2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={raiffeseiLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929785811?h=e0d2f5b35a')}
                    >
                        <img src={robecoLogo} alt={'logo'}/>
                    </div>
                    <div
                        className={homeStyle.gridElement}
                        onClick={() => openPopup('https://player.vimeo.com/video/929802831?h=254ae97b8f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479')}
                    >
                        <img src={ubsLogo} alt={'logo'}/>
                    </div>
                </div>
                <img src={assetLine} alt={''} style={{width: '150%'}}/>
                <div className={homeStyle.header}>
                    <img src={alettiLogo} alt={'logo'} className={homeStyle.headerLogoAletti}/>
                    <img src={baLogo} alt={'logo'} className={homeStyle.headerLogoBa}/>
                </div>
            </div>


        </div>
    );
}

export default Home;